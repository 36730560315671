import React, { useState, useEffect, useRef } from 'react';
import { 
  ChevronRight, 
  Zap, 
  Code, 
  Rocket, 
  ArrowRight,
  Cpu
} from 'lucide-react';
import './index.css';

function App() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [animateHeader, setAnimateHeader] = useState(false);
  const solutionsRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => setAnimateHeader(true), 300);
    
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('opacity-100', 'translate-y-0');
            entry.target.classList.remove('opacity-0', 'translate-y-10');
          }
        });
      },
      { threshold: 0.1 }
    );

    if (solutionsRef.current) {
      observer.observe(solutionsRef.current);
    }

    return () => {
      clearTimeout(timer);
      observer.disconnect();
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Thank you for your interest! We will contact you soon.');
    setEmail('');
    setMessage('');
  };

  return (
    <div className="bg-gradient-to-br from-[#0A192F] via-[#112240] to-[#0A192F] min-h-screen text-white relative overflow-hidden">
      <nav className="fixed top-0 left-0 w-full bg-[#0A192F]/80 backdrop-blur-md z-50 shadow-lg">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <div className="flex items-center">
            <div className="h-10 w-10 mr-3 bg-gradient-to-br from-blue-500 to-purple-600 rounded-full flex items-center justify-center">
              <Cpu className="w-6 h-6 text-white" />
            </div>
            <span className="text-2xl font-bold tracking-tight">Unyverse AI</span>
          </div>
          <div className="space-x-4">
            <a href="#solutions" className="hover:text-blue-400 transition tracking-wide font-medium">
              Solutions
            </a>
            <a href="#contact" className="hover:text-blue-400 transition tracking-wide font-medium">
              Contact
            </a>
          </div>
        </div>
      </nav>

      <header className={`container mx-auto px-4 pt-24 pb-16 text-center transition-all duration-1000 
        ${animateHeader ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
        <h1 className="text-5xl md:text-6xl font-extrabold mb-6 leading-tight bg-clip-text text-transparent bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 animate-gradient-x">
          Enterprise AI Solutions <br />that <span>Transform Businesses</span>
        </h1>
        <p className="text-xl text-gray-400 max-w-3xl mx-auto mb-8 tracking-wide leading-relaxed">
          Revolutionize your organization with cutting-edge AI and automation. Our custom solutions leverage advanced machine learning to drive unprecedented efficiency and innovation.
        </p>
        <div className="flex justify-center space-x-4">
          <a 
            href="#solutions" 
            className="inline-flex items-center bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 text-white font-bold py-3 px-6 rounded-full transition transform hover:scale-105 group"
          >
            Explore Solutions
            <ChevronRight className="ml-2 group-hover:translate-x-1 transition" />
          </a>
          <a 
            href="#contact" 
            className="inline-flex items-center border-2 border-blue-500 text-blue-400 hover:bg-blue-500/10 font-bold py-3 px-6 rounded-full transition transform hover:scale-105"
          >
            Contact Us
          </a>
        </div>
      </header>

      <section 
        id="solutions" 
        ref={solutionsRef}
        className="container mx-auto px-4 py-16 opacity-0 translate-y-10 transition-all duration-1000"
      >
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500">
            Our AI Solutions
          </h2>
          <p className="text-xl text-gray-400 max-w-2xl mx-auto tracking-wide">
            Intelligent applications designed to solve complex business challenges and drive digital transformation
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {[
            {
              icon: <Zap className="w-12 h-12 text-blue-500" />,
              title: "AI Workflow Automation",
              description: "Intelligent process automation that eliminates bottlenecks and maximizes operational efficiency.",
              gradient: "from-blue-500 to-cyan-500",
              titleColor: "text-blue-900 dark:text-blue-200"
            },
            {
              icon: <Code className="w-12 h-12 text-green-500" />,
              title: "Custom AI Models",
              description: "Bespoke machine learning solutions tailored precisely to your unique business requirements.",
              gradient: "from-green-500 to-teal-500",
              titleColor: "text-green-900 dark:text-green-200"
            },
            {
              icon: <Rocket className="w-12 h-12 text-purple-500" />,
              title: "Enterprise AI Platform",
              description: "Comprehensive AI ecosystem that scales intelligently with your organization's evolving needs.",
              gradient: "from-purple-500 to-pink-500",
              titleColor: "text-purple-900 dark:text-purple-200"
            }
          ].map((solution, index) => (
            <div 
              key={index} 
              className={`bg-[#112240] border border-gray-800 p-6 rounded-2xl 
                hover:scale-105 hover:shadow-2xl transition transform 
                relative overflow-hidden group`}
            >
              <div className={`absolute inset-0 bg-gradient-to-r ${solution.gradient} opacity-10 group-hover:opacity-20 transition-all duration-300`}></div>
              
              <div className="relative z-10">
                {solution.icon}
                <h3 className={`text-2xl font-bold mt-4 mb-2 tracking-tight ${solution.titleColor}`}>{solution.title}</h3>
                <p className="text-gray-400">{solution.description}</p>
                <div className="mt-4 opacity-0 group-hover:opacity-100 transition-opacity">
                  <a 
                    href="#contact" 
                    className="text-blue-400 hover:text-blue-300 flex items-center"
                  >
                    Learn More <ArrowRight className="ml-2 w-4 h-4" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section id="contact" className="container mx-auto px-4 py-16">
        <div className="max-w-xl mx-auto bg-[#112240] p-8 rounded-2xl border border-gray-800 shadow-2xl">
          <h2 className="text-3xl font-bold text-center mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500">
            Let's Transform Your Business
          </h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="email" className="block mb-2 text-gray-400">Email</label>
              <input 
                type="email" 
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full px-4 py-3 bg-[#0A192F] rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 border border-gray-700"
                placeholder="your.email@company.com"
              />
            </div>
            <div>
              <label htmlFor="message" className="block mb-2 text-gray-400">How can we help?</label>
              <textarea 
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
                rows={4}
                className="w-full px-4 py-3 bg-[#0A192F] rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 border border-gray-700"
                placeholder="Tell us about your AI and automation needs"
              />
            </div>
            <button 
              type="submit" 
              className="w-full bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 text-white font-bold py-3 rounded-lg transition transform hover:scale-105 flex items-center justify-center"
            >
              Send Inquiry <ArrowRight className="ml-2" />
            </button>
          </form>
        </div>
      </section>

      <footer className="bg-[#0A192F] py-8 border-t border-gray-800">
        <div className="container mx-auto px-4 text-center">
          <p className="text-gray-500">
            © 2024 Unyverse AI Solutions. Powering Business Transformation.
          </p>
        </div>
      </footer>

      <style>{`
        @keyframes gradient-x {
          0%, 100% { background-position: 0% 50%; }
          50% { background-position: 100% 50%; }
        }
        .animate-gradient-x {
          background-size: 200% 200%;
          animation: gradient-x 5s ease infinite;
        }
      `}</style>
    </div>
  );
}

export default App;